.contentarea {
	background-color: #ffffff;
	transition: padding 0.3s ease;
	max-width: 100vw;
	position: relative;
	@media (max-height: 606px) {
		min-height: 606px;
	}
	@media (max-width: 1367px) {
		padding-left: 0 !important;
	}
	.content-container {
		// overflow: hidden;
		width: 100%;
		padding: 0px 80px 50px;
		min-width: calc(100% - 72px);
		&.push-down {
			margin-top: 50px;
		}
		@media (max-width: 767px) {
			padding: 0px 15px 50px;
			min-width: 100%;
		}
	}
}
