$colors: (
	"light-blue": #1ddaff,
	"blue": #4361ee,
	"purple": #7209b7,
	"fuchsia": #db2ab2,
	"pink": #f72585,
	"salmon": #ef766a,
	"red": #ff004e,
	"dark-red": #c3033d,
	"validationRed": #fcdfe2,
	"green": #93e29e,
	"validationGreen": #e3fde7,
	"yellow": #ffe17c,
	"grey": #d1d3d4,
	"gray": #d1d3d4,
	"dark-grey": #414042,
	"light-grey": #f9f9f7,
	"header-default-grey": #555454,
);
