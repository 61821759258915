/// Generates a class for each color,
/// @param {map} $colors
@mixin useColorClasses($colors) {
	@each $name, $value in $colors {
		.#{$name} {
			background-color: $value;
		}
	}
}

/// Export SASS variables into CSS variables
/// @param {map} $colors
@mixin generateCssColorVariables($colors) {
	:root {
		@each $name, $value in $colors {
			--#{$name}: #{$value} !important;
		}
	}
}

/// Generate color-gradient animation
///
/// @param {string} $animationName
///	@param {color} $color
/// @param {string=background-color} $propertyName - the property to animate
/// @param {boolean=true} $lighten - whether to lighten (true) or darken (false) the given color
/// @param {number=0} $from
/// @param {number=10} $to
@mixin loadingBoxColorAnimation(
	$animationName,
	$color,
	$propertyName: background-color,
	$lighten: false,
	$darken: false,
	$from: 0,
	$to: 5,
	$animation: true
) {
	@if ($lighten == $darken) {
		@error "Params lighten(#{$lighten}) and darken(#{$darken}) are mutually exclusive ($lighten XOR $darken).";
	}
	$scaleDirection: if($lighten, 20%, -20%);
	$scaleDirection: if($darken, -20%, 20%);
	@keyframes #{$animationName} {
		@for $i from $from through $to {
			$scaleFactor: $i * $scaleDirection;
			#{$i * 10%} {
				#{$propertyName}: scale-color($color, $lightness: $scaleFactor);
			}
		}
	}
	@if (animation) {
		animation: #{$animationName} 2s linear infinite;
		animation-direction: alternate;
	}
}
