.select-tree-container {
	position: fixed;
	background: linear-gradient(
		to right,
		#1ddaff 0%,
		#27bbfb 14.9%,
		#4361ee 40.4%,
		#7209b7 66.5%,
		#e1208d 91.6%,
		#f72585 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ddaff', endColorstr='#f72585',GradientType=1 );
	width: 100vw;
	height: 100vh;
	z-index: 999;
	justify-content: center;
	align-items: center;
	display: flex;
	.select-box {
		background-color: white;
		border-radius: 5px;
		padding: 50px;
	}
	.subdomain-list {
		li {
			color: black;
			font-size: 18px;
			&:hover {
				cursor: pointer;
				background-color: var(--main-color);
				color: white;
			}
		}
	}
}
