@import "assets/css/abstracts/fontSizes";
@import "assets/css/abstracts/colors";

.error-toast-container {
	position: fixed;
	top: 1rem;
	right: 1rem;
	z-index: 999;
	padding: 1rem;
	border-radius: 0.25rem;
	border: 3px solid map-get($colors, "dark-red");
	background-color: map-get($colors, "red");
	color: #fff;
	width: 500px;

	.error-toast-close-btn {
		position: absolute;
		top: 0.25rem;
		right: 0.5rem;
		opacity: 0.75;
		font-weight: bolder;
		font-size: map-get($fontSizes, "x-large");
		cursor: pointer;
	}

	.error-toast-title {
		font-size: map-get($fontSizes, "medium");
		text-align: center;
		text-transform: uppercase;
		font-weight: bold;
	}

	.error-toast-description {
		font-size: map-get($fontSizes, "medium");
	}

	.error-toast-action {
		font-size: map-get($fontSizes, "small");
		text-align: center;
		margin-bottom: 0;
	}
}
