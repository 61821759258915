@import "assets/css/abstracts/colors.scss";
@import "assets/css/abstracts/fontSizes";

.tree-terminated-container {
	background: linear-gradient(
		to right,
		#1ddaff 0%,
		#27bbfb 14.9%,
		#4361ee 40.4%,
		#7209b7 66.5%,
		#e1208d 91.6%,
		#f72585 100%
	);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#1ddaff', endColorstr='#f72585', GradientType=1);
	width: 100vw;
	height: calc(100vh - 150px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	.tree-terminated-header {
		background-color: white;
		border-radius: 5px;
		padding: 1rem 4rem;
	}

	.select-box {
		background-color: white;
		border-radius: 5px;
	}

	.subdomain-list {
		li {
			color: black;
			font-size: 1.125rem;

			&:hover {
				cursor: pointer;
				background-color: var(--main-color);
				color: white;
			}
		}
	}

	.ss-button {
		color: white;
	}
}

.trial-ended-modal {
	display: flex;
	justify-content: center;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 999;
	background-color: rgba(0, 0, 0, 0.75);

	.trial-ended-message-container {
		border-radius: 5px;
		background-color: #fff;
		padding: 2rem 4rem;
		width: 40%;
		font-size: map-get($fontSizes, "medium");
	}
}
