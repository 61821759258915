/** @format */
@import "assets/css/vendor-overrides/bootstrap-utilities";

.headerarea {
	// based on design height of 116px / 1080px === ~10% === 10vh
	height: 70px;
	min-height: 70px;
	width: 100%;
	z-index: 16;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 60px;
	background: #fff;
	box-shadow: 0 2px 6px rgba(0, 0, 0, 0.11);
	transition: top 0.3s;
	&.push-down {
		top: 50px;
	}
	.logo-container {
		.header-nav {
			display: flex;
		}
		.hamburger-navbar {
			display: none;
		}
		.logo {
			height: auto;
			width: 180px;
			max-width: 35%;
			margin: 0px;

			@media (max-width: 1367px) {
				margin: 0 0 1.2rem 1rem;
			}

			img {
				object-fit: contain;
				height: 100%;
				width: 100%;
			}
		}
		.logo-titles {
			display: flex;
			align-items: center;
			line-height: 1;
			text-decoration: none;
			color: black;
			&:hover {
				cursor: pointer;
				color: var(--main-color);
			}
			&.active {
				color: var(--main-color);
			}
			svg {
				width: 80%;
				height: 80%;
				padding: 5px;
			}
			h2 {
				line-height: 1;
				margin: 0 0 0 30px;
				padding-left: 31px;
				font-weight: 600;
				font-size: 16px;
				text-decoration: none;
				color: inherit;
			}
		}
	}
	$chart_colors: (
		#3cbfea,
		#4361ee,
		#825dc8,
		#db2ab2,
		#f72585,
		#ef766a,
		#ff004e,
		#fcdfe2,
		#93e29e,
		#e3fde7,
		#ffe17c
	);
	.account-container {
		position: relative;
		display: inline-block;
		.account-name {
			width: 47px;
			height: 47px;
			border-radius: 50%;
			background-color: var(--main-color);
			display: flex;
			align-items: center;
			color: #fff;
			justify-content: center;
			font-size: 22px;
			font-family: "Montserrat", sans-serif;
			font-weight: 500;
			text-transform: uppercase;
			padding-top: 3px;
			cursor: pointer;
			overflow: hidden;
			position: relative;
			p {
				margin: 0;
				text-transform: uppercase;
				line-height: 1;
				user-select: none;
				pointer-events: none;
			}
			img {
				position: absolute;
				top: 0;
				bottom: 0;
				margin: 0;
				left: 50%;
				transform: translateX(-50%);
				min-height: 100%;
				max-height: 100%;
				width: auto;
				max-width: max-content;
				min-width: 100%;
			}
		}
		.account-menu {
			position: absolute;
			top: 100%;
			z-index: 22;
			background-color: #fff;
			right: 0;
			width: 238px;
			box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
			display: none;
			cursor: default;
			max-height: calc(100vh - 70px);
			overflow-y: auto;
			&.active {
				display: flex;
				flex-flow: column nowrap;
				align-items: flex-start;
				justify-content: space-between;
				overflow: hidden;
				& > .account-links,
				.logout-box {
					width: 100%;
				}
			}
			.account-links {
				padding: 15px 30px 0;
				border-bottom: 1px solid theme-color("light");
				max-height: 230px;
				overflow-y: auto;
				.account-link {
					display: block;
					margin: 15px 0;
					font-size: 15px;
					color: #000;
					font-weight: 300;
					&:hover {
						text-decoration: none;
						font-weight: 600;
						color: var(--main-color);
					}
					&.active {
						font-weight: 600;
						color: var(--main-color);
					}
				}
				h3 {
					font-size: 15px;
					font-weight: 500;
				}
				&:last-of-type {
					border-bottom: none;
					display: none;
				}
				.account-email {
					text-align: center;
					max-width: 100%;
					text-overflow: ellipsis;
					width: 100%;
					white-space: nowrap;
					overflow: hidden;
				}
				.account {
					width: 100%;
					padding: 0 5px;
					display: flex;
					justify-content: flex-start;
					align-items: center;
					border-bottom: 1px solid theme-color("light");
					&.active {
						background-color: aliceblue;
					}
					&:last-of-type {
						border-bottom: none;
					}
					&.disabled {
						pointer-events: none;
						&:hover {
							cursor: none;
							color: initial;
							background: initial;
						}
					}
					&:hover {
						cursor: pointer;
						background-color: aliceblue;
						color: var(--main-color);
					}
					.account-username {
						font-size: 0.8rem;
						margin: 0;
						max-width: 150px;
					}
					.account-subdomain {
						font-size: 0.7rem;
					}
					.account-name {
						width: 30px;
						height: 30px;
						min-width: 30px;
						min-height: 30px;
						border-radius: 50%;
						margin: 7px 7px 7px 0;
						background-color: var(--main-color);
						display: flex;
						align-items: flex-start;
						padding-top: 6px;
						color: #fff;
						justify-content: center;
						font-size: 15px;
						font-family: "Montserrat", sans-serif;
						font-weight: 500;
						overflow: hidden;
						@for $i from 1 through length($chart_colors) {
							&:nth-child(#{length($chart_colors)}n + #{$i}) {
								background-color: nth($chart_colors, $i);
							}
						}
					}
				}

				.add-account {
					color: var(--main-color);
					font-size: 0.9rem;
					white-space: nowrap;
					margin: 1rem 1rem 1rem 0.5rem;

					&:hover {
						cursor: pointer;
					}
				}
			}

			.logout-box {
				background-color: #f7f7f7;
				padding: 15px 30px 0;
				.logout-account {
					font-size: 10px;
					color: #000;
					font-weight: 300;
					line-height: 1.2;
				}
				a {
					font-size: 15px;
					font-weight: 300;
					color: #54c9ed;
					margin-top: 20px;
				}
				.logout-link {
					display: block;
					margin: 15px 0;
					font-size: 15px;
					color: #000;
					cursor: pointer;
					font-weight: 300;
					&:hover {
						text-decoration: none;
						font-weight: 600;
						color: var(--main-color);
					}
					&.active {
						font-weight: 600;
						color: var(--main-color);
					}
				}
			}
		}
	}
	.account-box {
		display: flex;
		align-items: center;
		img {
			margin: 0 20px;
		}
	}
}

.search {
	position: relative;
	height: 50px;
	width: 300px;
	margin-right: 30px;
	&.on {
		-webkit-animation-name: in-out;
		animation-name: in-out;
		-webkit-animation-duration: 0.7s;
		animation-duration: 0.7s;
		-webkit-animation-timing-function: linear;
		animation-timing-function: linear;
		-webkit-animation-iteration-count: 1;
		animation-iteration-count: 1;
	}

	input {
		box-sizing: border-box;
		width: 25px;
		height: 25px;
		border: 3px solid #c7c9cb;
		border-radius: 50%;
		background: none;
		color: #000;
		outline: 0;
		font-size: 1.5rem;
		-webkit-transition: width 0.4s ease-in-out, border-radius 0.8s ease-in-out,
			padding 0.2s;
		transition: width 0.4s ease-in-out, border-radius 0.8s ease-in-out,
			padding 0.2s;
		-webkit-transition-delay: 0.4s;
		transition-delay: 0.4s;
		position: absolute;
		top: 8px;
		right: 0;
	}

	.search-btn {
		background: none;
		position: absolute;
		top: 0;
		right: 0;
		height: 30px;
		width: 30px;
		padding: 0;
		border-radius: 100%;
		outline: 0;
		border: 0;
		color: inherit;
		cursor: pointer;
		-webkit-transition: 0.2s ease-in-out;
		transition: 0.2s ease-in-out;
	}

	.search-btn::before {
		content: "";
		position: absolute;
		width: 15px;
		height: 4px;
		background-color: #c7c9cb;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		margin-top: 18px;
		margin-left: 9px;
		-webkit-transition: 0.2s ease-in-out;
		transition: 0.2s ease-in-out;
	}

	.close {
		-webkit-transition: 0.4s ease-in-out;
		transition: 0.4s ease-in-out;
		-webkit-transition-delay: 0.4s;
		transition-delay: 0.4s;
	}

	.close:before {
		content: "";
		position: absolute;
		width: 27px;
		height: 4px;
		margin-top: 8px;
		margin-left: -13px;
		background-color: #c7c9cb;
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		-webkit-transition: 0.2s ease-in-out;
		transition: 0.2s ease-in-out;
	}

	.close:after {
		content: "";
		position: absolute;
		width: 27px;
		height: 4px;
		background-color: #c7c9cb;
		margin-top: 8px;
		margin-left: -13px;
		cursor: pointer;
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
	}

	.square {
		box-sizing: border-box;
		padding: 0 40px 0 10px;
		width: 300px;
		height: 40px;
		border: 2px solid #c7c9cb;
		border-radius: 0;
		background: none;
		outline: 0;
		-webkit-transition: width 0.4s ease-in-out, border-radius 0.4s ease-in-out,
			padding 0.2s;
		transition: width 0.4s ease-in-out, border-radius 0.4s ease-in-out,
			padding 0.2s;
		-webkit-transition-delay: 0.4s, 0s, 0.4s;
		transition-delay: 0.4s, 0s, 0.4s;
		top: 5px;
	}
}

@media only screen and (max-width: 1367px) {
	.headerarea {
		padding: 0;
		flex-direction: column;
		align-items: flex-start;
		.logo-container {
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			width: 100%;
			height: 100%;
			.logo-titles {
				align-items: flex-start;
				flex-flow: column nowrap;
				padding: 8px 10px;
				img {
					max-width: 220px;
					max-height: 50px;
				}
				h2 {
					display: none;
				}
			}
			.hamburger-navbar {
				padding: 10px;
				display: flex;
				align-items: center;
				height: 100%;
				width: 120px;
				justify-content: center;
				&:hover {
					.line {
						stroke: var(--main-color);
					}
				}
				.hamburger {
					width: 100%;
					height: 100%;
					.line {
						fill: none;
						stroke: black;
						stroke-width: 5;
						stroke-linecap: round;
						transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
							stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1),
							stroke 200ms ease;
					}
					.line1 {
						stroke-dasharray: 45 207;
						stroke-width: 6;
					}
					.line2 {
						stroke-dasharray: 60 60;
						stroke-width: 6;
					}
					.line3 {
						stroke-dasharray: 45 207;
						stroke-width: 6;
					}
				}

				&.active {
					.hamburger {
						.line {
							stroke: var(--main-color);
						}
						.line1 {
							stroke-dasharray: 90 207;
							stroke-dashoffset: -134;
							stroke-width: 6;
						}
						.line2 {
							stroke-dasharray: 1 60;
							stroke-dashoffset: -30;
							stroke-width: 6;
						}
						.line3 {
							stroke-dasharray: 90 207;
							stroke-dashoffset: -134;
							stroke-width: 6;
						}
					}
				}
			}
		}

		.account-box {
			display: none;
		}
	}
	.search {
		margin-right: 0;
		width: 90%;
	}
}
.mobile-navigation {
	display: none;
}

@media only screen and (max-width: 1367px) {
	.mobile-navigation {
		width: 0;
		overflow-y: auto;
		height: 100vh;
		z-index: 25;
		transition: all 0.3s ease;
		max-height: calc(100vh - 65px);
		opacity: 0;
		position: absolute;
		right: 0;
		top: 70px;
		transform: inherit;
		display: flex;
		justify-content: flex-end;
		max-height: calc(100vh - 70px);
		.inner-mobile-navigation {
			background: #fafafa;
			width: 100%;
			max-width: 550px;
			position: relative;
			overflow-y: auto;
			padding-bottom: 150px;
			.logout-link:hover {
				text-decoration: underline;
				text-decoration-color: #007bff;
			}
			.settings-link:hover {
				text-decoration: underline;
				text-decoration-color: #007bff;
			}
			.settings-arrow {
				margin-right: 20px;

				svg {
					max-width: 10px !important;
					height: auto;
					width: 100%;
					overflow: visible;
					path,
					rect {
						fill: theme-color("dark");
						stroke: theme-color("dark");
					}
				}
			}
		}
		&::-webkit-scrollbar,
		&::-webkit-scrollbar-track,
		&::-webkit-scrollbar-thumb {
			width: 0;
		}
		&.active {
			transform: translateX(0);
			opacity: 1;
			width: 100%;
		}
		a {
			text-decoration: none;
		}
		.mobile-account-box {
			flex-direction: row-reverse;
			align-items: center;
			background: #fafafa;
			justify-content: space-between;
			width: 100%;
			position: relative;
			z-index: 4;
			border-bottom: 1px solid #cecece;
			max-width: 480px;
			margin: 25px auto 0 auto;
			padding: 0 25px 15px;
			h4 {
				color: theme-color("dark");
				width: calc(100% - 70px);
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				padding: 5px !important;
				display: inline !important;
				text-transform: capitalize;
			}
			h6 {
				color: theme-color("dark");
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;
				padding: 5px !important;
			}
			.account-name {
				width: 70px;
				height: 70px;
				margin-right: 10px;
				border-radius: 50%;
				background-color: var(--main-color);
				color: var(--white);
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 22px;
				font-family: "Montserrat", sans-serif;
				font-weight: 500;
				overflow: hidden;
				flex: 0 0 70px;
				p {
					margin: 0;
					text-transform: uppercase;
					line-height: 1;
					user-select: none;
					pointer-events: none;
				}
				img {
					object-fit: cover;
					max-height: 100%;
					max-width: max-content;
					min-width: 100%;
				}
			}
			.mobile-account-links {
				padding-top: 35px;
				margin: 25px -25px 0;
				text-align: center;
				border-top: 1px solid #cecece;
				overflow-y: auto;
				max-height: 200px;
				h3 {
					font-size: 13px;
					margin-bottom: 15px;
				}

				.mobile-account {
					cursor: pointer;
					&:last-child p {
						margin-bottom: 0;
					}
					&:hover {
						cursor: pointer;
						background-color: aliceblue;
						color: var(--main-color);
					}
				}
			}
		}

		.nav-item {
			cursor: pointer;
			transition: background-color 0.2s;
			svg {
				max-width: 25px;
				height: auto;
				width: 100%;
				overflow: visible;
				path,
				rect {
					fill: theme-color("dark");
					stroke: theme-color("dark");
				}
			}

			img {
				min-width: 25px;
				max-height: 25px;
				max-width: 25px;
			}
			.ReactCollapse--collapse {
				transition: height 500ms ease-in-out;
			}
			.Collapse-content {
				background: #ffffff;
				border-bottom: 2px solid #cecece;
				.submenu-label {
					color: black;
				}
				h5 {
					color: black;
				}
				svg {
					path,
					rect,
					circle {
						stroke: black;
						fill: black;
					}
				}
				.nav-item.active {
					* {
						color: var(--main-color);
					}
					background: #fafafa;
					& > div {
						background: transparent;
					}
					svg {
						path,
						rect,
						circle {
							stroke: var(--main-color);
							fill: var(--main-color);
						}
					}
				}
			}

			.nav-arrow {
				flex: 1 0 auto;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				position: relative;
				&::after {
					width: 0.75rem;
					height: 0.75rem;
					border-top: 2px solid theme-color("dark");
					border-right: 2px solid theme-color("dark");
					transform: rotate(45deg);
					position: relative;
					content: "";
				}
				&.open {
					&::after {
						transform: rotate(135deg);
					}
				}
			}
			&.active {
				& > div {
					background: var(--main-color);
					color: #ffffff;

					h4 {
						color: inherit;
					}

					svg {
						path,
						rect,
						circle {
							fill: #ffffff;
							stroke: #ffffff;
						}
					}
				}

				.nav-arrow {
					&::after {
						border-top-color: white;
						border-right-color: white;
					}
				}
				.Collapse-content {
					background: white;

					.nav-item {
						h5 {
							color: black;
						}
						svg {
							path,
							rect,
							circle {
								stroke: black;
								fill: black;
							}
						}
						&.active {
							background: #fafafa;
							color: var(--main-color);

							& > div {
								background: transparent;
							}

							h5 {
								color: var(--main-color);
							}

							svg {
								path,
								rect,
								circle {
									fill: var(--main-color);
									stroke: var(--main-color);
								}
							}
						}
					}
				}
			}
		}
		.nav-logo {
			position: absolute;
			bottom: 30px;
			left: 50%;
			transform: translateX(-50%);
		}

		.mobile-navigation-settings {
			.back-button-container {
				padding-top: 1.5rem !important;
				padding-left: 1.5rem !important;
				padding-bottom: 0.5rem !important;

				.back-button {
					text-transform: uppercase;
					border: none;
					background: none;
					margin-right: 25px;
					cursor: pointer;
					display: inline-flex;
					align-items: center;
					vertical-align: middle;
					font-weight: 500;
					font-size: 13px;
				}

				.back-button svg {
					margin-right: 7px;
					max-width: 10px !important;
					height: auto;
					width: 100%;
					overflow: visible;
					path,
					rect {
						fill: theme-color("dark");
						stroke: theme-color("dark");
					}
				}
			}
		}
	}
	// .push-down .mobile-navigation {
	// 	max-height: calc(100vh - 120px);
	// }
}
