/** @format */

@import "vendor-overrides/_index.scss";
@import "abstracts/_colors.scss";
@import "abstracts/_mixins.scss";

@include useColorClasses($colors);
@include generateCssColorVariables($colors);

$arrow-icon-path: "../images/icons/arrow.svg";
$banner_height: 50px;

html,
body {
	font-family: "Montserrat", sans-serif;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.2;
	color: #000;
	background-color: #ffffff;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-rendering: optimizeLegibility;
	-webkit-tap-highlight-color: transparent;
}

body {
	overflow: auto;
	overflow-x: hidden;
	max-height: 100%;
	height: 100%;
	width: 100%;
}

@include media-breakpoint-down(md) {
	body {
		font-size: 14px;
	}
}

.error-500-page {
	h2 {
		text-transform: unset;
		color: #000;
		font-size: 60px;
	}
	h4 {
		color: #000;
		margin: 20px 0 15px;
		font-size: 30px;
		text-transform: unset;
	}
	p {
		font-size: 19px;
		color: #6d6e71;
	}
}
.edit-container-fixed {
	position: fixed;
	top: 0;
	right: 0;
	width: 100%;
	height: 100%;
}
#video-container {
	position: relative;
	span {
		opacity: 0;
		background: rgba(255, 255, 255, 0.562);
		border-radius: 50%;
		position: absolute;
		top: 35%;
		left: 43%;
		height: 140px;
		width: 140px;
		transition: all 0.2s ease;
		&:hover {
			transform: scale(1.1);
			cursor: pointer;
		}
		&::before {
			content: "";
			position: absolute;
			top: 24%;
			left: 35%;
			width: inherit;
			height: auto;
			border-top: 40px solid transparent;
			border-bottom: 40px solid transparent;
			border-left: 60px solid white;
		}
	}
	&:hover {
		span {
			opacity: 1;
		}
	}
	video {
		outline: none;
	}
}
img,
svg {
	max-width: 100%;
}

*,
*::after,
*::before {
	box-sizing: border-box;
}

.app {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	.banner {
		position: relative;
	}
	&.account {
		.main-footer {
			display: none;
		}
	}
}

.banner-container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background: rgba(255, 255, 255, 0.532);
	color: white;
	text-transform: uppercase;
	text-align: center;
	padding: 16px;
	height: 100%;
	z-index: 999;
}

.tracks-overview {
	padding-top: $banner_height;
}

.banner-box {
	width: 100%;
	background-color: map-get($colors, "red");
	color: white;
	text-transform: uppercase;
	text-align: center;
	padding: 13px 15px;
	z-index: 100;
	font-size: 16px;
	height: 50px;
	line-height: 1.2;
	display: flex;
	align-items: center;
	justify-content: center;
	strong {
		vertical-align: middle;
		margin-right: 15px;
	}

	.btn {
		margin: 0;
		min-width: 118px;
		border-color: white !important;
		background-color: white !important;
		color: map-get($colors, "red") !important;
	}

	.banner-buttons {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		vertical-align: middle;
	}

	.banner {
		flex: 1 1 auto;
	}
	strong {
		font-weight: 500;
	}
	strong a {
		color: #fff;
		letter-spacing: 0;
		text-decoration: underline;
	}

	@media (max-width: 1150px) {
		font-size: 12px;
		padding: 11px 15px;
		.banner {
			display: flex;
			align-items: center;
			justify-content: space-between;
			text-align: left;
		}
	}
	@media (max-width: 600px) {
		.btn {
			display: none;
		}
		.banner {
			text-align: center;
			justify-content: center;
		}
	}
	@media (max-width: 400px) {
		font-size: 10px;
		padding: 10px;
	}
}

.loading-screen {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 50;
	background: #1b1b1b;
	display: flex;
	align-items: center;
	justify-content: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Montserrat", sans-serif;
	color: map-get($colors, "header-default-grey");
	font-weight: 400;
}

h2,
h3,
h4,
h5,
h6 {
	//text-transform: uppercase;
}

h1 {
	font-size: 38px;
	font-weight: 300;
}

h2 {
	font-size: 30px;
	font-weight: 700;
}

h3 {
	font-size: 26px;
	font-weight: 700;
}

h4 {
	font-size: 23px;
	font-weight: 400;
}

h5 {
	font-size: 20px;
	font-weight: 300;
}

h6 {
	font-size: 17px;
	font-weight: 400;
}
hr {
	width: 100%;
}
a {
	&.disabled {
		cursor: none;
		pointer-events: none;
	}
}
select {
	display: block;
	font-size: 16px;
	font-family: sans-serif;
	font-weight: 700;
	color: #444;
	line-height: 1.3;
	padding: 0.6em 1.4em 0.5em 0.8em;
	width: 100%;
	max-width: 100%;
	box-sizing: border-box;
	margin: 0;
	border: 1px solid #aaa;
	box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.04);
	border-radius: 0.5em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: #fff;
	background-image: url($arrow-icon-path),
		-webkit-linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%); /* For Chrome and Safari */
	background-image: url($arrow-icon-path),
		-moz-linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%); /* For old Firefox (3.6 to 15) */
	background-image: url($arrow-icon-path),
		-ms-linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%); /* For pre-releases of Internet Explorer  10*/
	background-image: url($arrow-icon-path),
		-o-linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%); /* For old Opera (11.1 to 12.0) */
	background-image: url($arrow-icon-path),
		linear-gradient(to bottom, #ffffff 0%, #e5e5e5 100%); /* Standard syntax; must be last */
	background-repeat: no-repeat, repeat;
	background-position: right 0.7em top 50%, 0 0;
	background-size: 0.65em auto, 100%;
}
select::-ms-expand {
	display: none;
}
select:hover {
	border-color: #888;
}
select:focus {
	border-color: #aaa;
	box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
	box-shadow: 0 0 0 3px -moz-mac-focusring;
	color: #222;
	outline: none;
}
select option {
	font-weight: normal;
}
.svg-hover-blue {
	path {
		transition: all 0.1s ease;
	}
	&:hover {
		cursor: pointer;
		path {
			stroke: var(--main-color) !important;
			fill: var(--main-color) !important;
		}
	}
}
small {
	&.smaller {
		font-size: 0.7rem;
	}
}
.d-grid {
	display: grid;
}
// opacity
.opacity-0 {
	opacity: 0;
}
// text colors
.grey-text,
.gray-text {
	color: #555454;
}
.nodecoration {
	color: #000;
	text-decoration: none;
	&:hover {
		text-decoration: none;
	}
}
.center-crap {
	display: flex;
	justify-content: center;
	align-items: center;
}
.border-top-blue {
	border: 1px solid theme-color("light");
	border-top: 4px solid var(--main-color) !important;
}
.blue-text {
	color: var(--main-color);
	&.hover {
		&:hover {
			cursor: pointer;
		}
	}
}
.bg-blue-hover {
	&:hover {
		color: white;
		background-color: var(--main-color);
	}
}
.cursor {
	&:hover {
		cursor: pointer;
	}
}
.no-border {
	border: none !important;
	&:focus {
		outline: none;
		box-shadow: none;
	}
}
.text-success {
	color: theme-color("success") !important;
}
.text-danger {
	color: theme-color("danger") !important;
}
.blue-text-hover {
	transition: color 0.1s !important;
	&:hover {
		text-decoration: none;
		color: var(--main-color) !important;
		cursor: pointer;
		& > * {
			text-decoration: none;
			color: var(--main-color) !important;
			cursor: pointer;
		}
	}
	& > * {
		&:hover {
			text-decoration: none;
			color: var(--main-color) !important;
			cursor: pointer;
		}
	}
}
.remove-category-swal {
	background: theme-color("danger");
	color: #fff !important;
}
.small-scrollbar {
	&::-webkit-scrollbar {
		width: 5px;
	}
	/* Track */
	&::-webkit-scrollbar-track {
		box-shadow: none;
		background: #edebeb;
	}
	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: theme-color("light");
		background: theme-color("light");
	}
	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: var(--main-color);
	}
}
::-webkit-scrollbar {
	width: 15px;
	@media (max-width: 767px) {
		width: 8px;
	}
}
/* Track */
::-webkit-scrollbar-track {
	box-shadow: none;
	background: #efefef;
}
/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--main-color);
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: map-get($colors, "blue");
}

div[class*="css"][class*="container"] ::-webkit-scrollbar {
	width: 8px !important;
}
.list-style-none {
	list-style: none;
}
.grey-border,
.gray-border {
	border: 2px solid theme-color("light");
}
.mw-50 {
	max-width: 50%;
}
.w-30 {
	width: 30%;
}
.nowrap {
	white-space: nowrap;
}
.bg-light-blue {
	background: var(--main-color);
}
.bg-light-grey,
.bg-light-gray {
	background-color: #f7f7f7;
}
.bg-green {
	background: theme-color("success");
}
.box.bg-white {
	background: #fff;
	border-radius: 10px;
	padding: 20px;
	border: 1px solid theme-color("light");
	min-height: unset;
	&.active {
		background: #f7f7f7 !important;
	}
}
.toggle-inherited {
	border-bottom: 3px solid #6d6e71;
	margin: 20px 0;
	width: 100%;
	button {
		color: white;
		border: #6d6e71;
		background-color: rgba(109, 110, 113, 0.226);
		margin-right: 6px;
		text-transform: uppercase;
		padding: 10px 20px;
		font-weight: 700;
		&.active {
			background-color: rgb(109, 110, 113);
		}
	}
}

.colored-circles {
	display: flex;
	flex-flow: row wrap;
	.colored-circle {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 30px;
		height: 30px;
		margin-right: 10px;
		margin-bottom: 5px;
		border-radius: 50%;
		transition: transform 0.1s ease-in;
		position: relative;
		&.active::after {
			content: "";
			display: block;
			position: absolute;
			top: 3px;
			bottom: 3px;
			left: 3px;
			right: 3px;
			border-radius: 50%;
			border: 2px solid #fff;
		}
		&:hover {
			cursor: pointer;
			transform: scale(1.1);
		}
	}

	img {
		width: 55%;
		height: 55%;
	}
}

.custom-color-picker-container {
	display: flex;
	align-items: center;
	border-left: 2px solid #dee2e6;
	margin: 0 0 5px 10px;
	padding-left: 20px;
}

.btn {
	cursor: pointer;
	&:focus {
		outline: rgba(210, 217, 224, 0.25);
	}
}
.btn.btn-clear,
.btn.btn-large,
.btn.btn-regular,
.btn.btn-regular-pink,
.btn.btn-small {
	background-color: var(--main-color);
	font-family: "Montserrat", sans-serif;
	font-size: 19px;
	text-transform: uppercase;
	color: #fff;
	font-weight: 700;
	border-radius: 5px;
	line-height: 1.2;
	letter-spacing: 1px;
	padding: 10px 25px;
	cursor: pointer;
	margin: 10px;
	border: 2px solid var(--main-color);
}

.btn.btn-clear {
	text-transform: uppercase;
	background-color: transparent;
	color: #96999c;
	font-size: 12px;
	margin: auto 0;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	border: 2px solid #96999c;
	&:focus {
		outline: none;
	}
	&.btn-small {
		font-size: 10px;
		padding: 5px 15px;

		flex-flow: row nowrap;
		color: #96999c;
	}
	&.active {
		color: white;
		background-color: theme-color("light");
		border: 2px solid theme-color("light");
	}
	&.disabled {
		opacity: 0.8;
		color: white;
		background-color: theme-color("light");
		pointer-events: none;
	}
}
.btn.btn-regular,
.btn.btn-regular-pink {
	font-size: 13px;
}

.btn.btn-regular-pink {
	background: var(--secondary-color);
	border-color: var(--secondary-color);
}

.btn.btn-small {
	font-size: 9px;
	padding: 5px 10px;
}

.btn-wrapper {
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-columns: 1fr;
	grid-auto-flow: column;
	grid-column-gap: 15px;
	place-content: stretch;
}

.btn-modal {
	margin-right: -10px;
	height: 55px;
	align-items: center;
	& > .btn {
		padding: 5px 25px !important;
		font-size: 17px !important;
	}
	@media (max-width: 700px) {
		margin-right: 0;
		height: 55px;
		& > .btn {
			padding: 5px 14px !important;
			font-size: 12px !important;
		}
	}
	@media (max-width: 400px) {
		& > .btn {
			height: fit-content;
			margin: 5px !important;
			padding: 4px 7px !important;
			font-size: 11px !important;
		}
	}
}

.btn.btn-clear:hover {
	color: white;
	background-color: theme-color("light");
	border: 2px solid theme-color("light");
}

.btn.btn-large:hover:enabled,
.btn.btn-regular:hover:enabled,
.btn.btn-small:hover:enabled {
	color: var(--main-color);
	background-color: #fff;
	border: 2px solid var(--main-color);
}

.btn.btn-cancel,
.btn.btn-accept {
	font-family: "Montserrat", sans-serif;
	font-size: 9px;
	text-transform: uppercase;
	font-weight: 700;
	border-radius: 5px;
	background-color: transparent;
	padding: 3px 10px;
	letter-spacing: 0.5px;
	margin: 10px;
}
.btn.btn-accept {
	color: theme-color("success");
	border: 2px solid theme-color("success");
	&.active {
		color: #fff;
		background-color: theme-color("success");
		border-color: theme-color("success");
	}
	&:hover:enabled {
		color: #fff;
		background-color: theme-color("success");
		border-color: theme-color("success");
	}
}

.btn.btn-cancel {
	color: theme-color("danger");
	border: 2px solid theme-color("danger");
	&.active {
		color: #fff;
		background-color: theme-color("danger");
	}
	&:hover:enabled {
		color: #fff;
		background-color: theme-color("danger");
		border-color: theme-color("danger");
	}
}

.btn-small-grey.btn {
	font-family: "Montserrat", sans-serif;
	font-size: 9px;
	text-transform: uppercase;
	font-weight: 700;
	border-radius: 5px;
	padding: 3px 5px;
	background-color: theme-color("light");
	color: #fff;
	letter-spacing: 0.5px;
	margin: 10px;
	border: 2px solid theme-color("light");
}

.btn-small-grey.btn:hover:enabled {
	color: theme-color("light");
	background-color: transparent;
}

.btn-small-grey-user.btn {
	font-family: "Montserrat", sans-serif;
	font-size: 9px;
	text-transform: uppercase;
	font-weight: 700;
	border-radius: 5px;
	padding: 3px 5px;
	background-color: #fff;
	color: theme-color("light");
	letter-spacing: 0.5px;
	margin: 10px;
	border: 2px solid theme-color("light");
	min-width: 55px;
	cursor: pointer;
}

.btn-small-grey-user.btn:hover:enabled {
	color: #fff;
	background-color: theme-color("light");
}

.btn-regular-grey.btn {
	font-family: "Montserrat", sans-serif;
	font-size: 13px;
	text-transform: uppercase;
	font-weight: 700;
	border-radius: 5px;
	background-color: theme-color("light");
	color: #fff;
	padding: 10px 25px;
	line-height: 1.2;
	margin: 10px;
	border: 2px solid theme-color("light");
}

.btn-regular-grey.btn:hover:enabled {
	background-color: #fff;
	color: theme-color("light");
	border-color: theme-color("light");
}

.btn-large-grey.btn {
	font-family: "Montserrat", sans-serif;
	font-size: 24px;
	text-transform: uppercase;
	font-weight: 300;
	border-radius: 5px;
	background-color: theme-color("light");
	padding: 10px 25px;
	color: #fff;
	letter-spacing: 0.5px;
	margin: 10px;
	border: 2px solid theme-color("light");
}

.btn-large-grey.btn:hover:enabled {
	background-color: #fff;
	color: theme-color("light");
}
.btn.btn-round-grey {
	margin: 10px 10px;
	padding: 2px 10px;
	background: #96999c;
	border-radius: 20px;
	color: white;
	text-transform: uppercase;
}
button,
input,
.btn {
	outline: none !important;
	&:disabled {
		cursor: default;
		opacity: 0.7;
	}
}
.org_node_box {
	display: inline-block;
	background-color: var(--main-color);
	width: 0.5rem;
	height: 0.5rem;
	margin-right: 3px;
}
.entity_box {
	display: inline-block;
	background-color: var(--secondary-color);
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	margin-right: 3px;
}

// styling for checkbox container
.app .checkbox-container {
	display: block;
	position: relative;
	padding-left: 25px;
	margin-bottom: 12px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	// Hiding the browsers default styling for the checkbox
	input {
		position: absolute;
		opacity: 0;
		cursor: pointer;
		height: 0;
		width: 0;
		&:disabled + span {
			cursor: default;
			color: rgba(128, 128, 128, 0.473);
			pointer-events: none;
			background-color: theme-color("light");
		}
	}
	&:hover input:not(:disabled) + .checkmark {
		background-color: theme-color("light");
	}

	input:checked + .checkmark {
		background-color: var(--main-color);
		border: 2px solid var(--main-color);
		&::after {
			display: block;
		}
	}
	.checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 15px;
		width: 15px;
		border-radius: 2px;
		border: 2px solid theme-color("light");
		&.big {
			&::after {
				left: 0.8rem;
				top: 0;
				width: 0.5rem !important;
				height: 1rem !important;
			}
		}
		&::after {
			content: "";
			position: absolute;
			display: none;
			left: 3.5px;
			top: 0;
			width: 5px !important;
			height: 10px !important;
			border: solid white;
			border-width: 0 3px 3px 0;
			transform: rotate(45deg);
			-webkit-transform: rotate(45deg);
			-ms-transform: rotate(45deg);
		}
	}
	&.disabled {
		cursor: default;
		color: rgba(128, 128, 128, 0.473);
		pointer-events: none;
		.checkmark {
			background-color: theme-color("light");
		}
		&:hover input + .checkmark {
			background-color: theme-color("light");
		}
		input {
			cursor: none;
		}
	}
}

.white {
	color: #fff;
}
input:disabled + .checkmark {
	opacity: 0.4;
	&:hover {
		background-color: theme-color("light");
	}
}
input:checked + .checkmark {
	background-color: var(--main-color);
	border: 2px solid var(--main-color);
	&::after {
		display: block;
	}
}
.pattern-background {
	background-image: url("../images/pattern-background.svg");
	background-size: auto;
	background-position: center;
	background-blend-mode: overlay;
	background-repeat: no-repeat;
}

.linear-gradient {
	opacity: 1;
	background: none;
	&::after,
	&::before {
		content: "";
		display: block;
		position: absolute;
		// top: 71px;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		max-height: 100%;
	}
	&::before {
		background: var(--page-header-color);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1ddaff', endColorstr='#f72585',GradientType=1 );
		animation: OpacityAnim 8s ease-in-out 0s infinite alternate;
	}
	&::after {
		background: var(--page-header-color);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f72585', endColorstr='#1ddaff',GradientType=1 );
		animation: OpacityAnim 8s ease-in-out calc(-1 * 8s) infinite alternate;
	}
	@keyframes OpacityAnim {
		0% {
			opacity: 1;
		}
		100% {
			opacity: 0;
		}
	}
}
.blur-bottom {
	position: relative;
	padding-bottom: 1.5rem;
	&:after {
		content: "";
		position: absolute;
		z-index: 1;
		bottom: 0;
		left: 0;
		pointer-events: none;
		background-image: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 1) 90%
		);
		width: 100%;
		height: 4em;
	}
}
.table td,
.table th {
	vertical-align: middle;
	/* white-space: nowrap; */
}
.line-clamp {
	overflow: hidden;
	display: -webkit-box;
	text-overflow: ellipsis;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	max-width: 100%;
	&.line-clamp-multi {
		-webkit-line-clamp: 2;
		white-space: normal;
	}
}
.pagination {
	margin-bottom: 30px;
	text-align: right;
	max-width: 100%;
	width: 100%;
	justify-content: flex-end;
	.user-page {
		width: 25px;
		height: 25px;
		display: inline-flex;
		position: relative;
		color: #000;
		margin: 0 5px;
		padding: 0;
		border-radius: 5px;
		align-items: center;
		justify-content: center;
		line-height: 1.2;
		text-decoration: none;
		background: transparent;
		box-shadow: none;
		border: 2px transparent solid;
	}
}
.pagination .user-page.selected,
.pagination .user-page:hover {
	background-color: var(--main-color);
	color: #fff;
}

.pagination .user-page.selected:hover {
	background-color: transparent;
	border: 2px var(--main-color) solid;
	color: var(--main-color);
}

.entity-border-top[data-color="0"] {
	border-top: 10px solid var(--main-color) !important;
}

.entity-border-top[data-color="1"] {
	border-top: 10px solid map-get($colors, "blue") !important;
}

.entity-border-top[data-color="2"] {
	border-top: 10px solid map-get($colors, "purple") !important;
}

.entity-border-top[data-color="3"] {
	border-top: 10px solid map-get($colors, "fuchsia") !important;
}

.entity-border-top[data-color="4"] {
	border-top: 10px solid var(--secondary-color) !important;
}

.edit-btn {
	transition: transform 200ms ease;
}
.edit-btn:hover {
	cursor: pointer;
	transform: scale(1.2);
}

.errorImg {
	min-width: 100vw;
	height: 100vh;
	z-index: 1;
}
.errorMsg {
	z-index: 2;
	right: 5%;
	top: 50%;
	transform: translateY(-50%);
}

.edit-btn {
	transition: transform 200ms ease;
	&.disabled {
		pointer-events: none;
		opacity: 0.8;
	}
}
.edit-btn:hover {
	cursor: pointer;
	transform: scale(1.2);
}

.type-track {
	background-color: map-get($colors, "purple") !important;
}

.type-lesson {
	background-color: map-get($colors, "blue") !important;
}

.type-in_person {
	background-color: map-get($colors, "fuchsia") !important;
}

$ratingGreyColor: #d0cccd;

.counter {
	transition: all 0.2s ease;
	line-height: 1;
}

.right {
	float: right;
}

// Hamburger and boxes hover and active styling
.hamburger {
	margin: 10px;
	&.mouseover {
		g {
			& > * {
				stroke: var(--main-color);
			}
		}
	}
	&:hover {
		cursor: pointer;
		g {
			& > * {
				fill: var(--main-color);

				stroke: var(--main-color);
			}
		}
	}
	@media (max-width: 700px) {
		&:hover {
			g {
				& > * {
					stroke: theme-color("light");
				}
			}
		}
	}
}
.hamburger.active {
	g {
		& > * {
			fill: var(--main-color);
			stroke: var(--main-color);
		}
	}
}
.boxes {
	margin: 10px;
	&:hover {
		cursor: pointer;
		& > * {
			fill: var(--main-color);
		}
	}
}
.boxes.active {
	& > * {
		fill: var(--main-color);
	}
}

.radio-buttons {
	display: flex;
	margin-top: 10px;
	flex-wrap: wrap;
	div {
		display: flex;
		align-items: flex-start;
	}
	input {
		width: 0 !important;
		opacity: 0;
		position: absolute !important;
		pointer-events: none;
		+ label {
			font-size: 19px;
			font-weight: 300;
			padding-left: 28px;
			margin-right: 10px;
			position: relative;
			line-height: 1;
			cursor: pointer;
			&::after {
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 1px;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				border: 1px solid var(--main-color);
			}
			&::before {
				content: "";
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				left: 5px;
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: #fff;
				transition: background-color 0.3s ease;
			}
		}
	}
	input:checked + label::before {
		background-color: var(--main-color);
	}
	&.black-radios {
		input {
			+ label {
				&::after {
					border: 1px solid black;
				}
				&::before {
					content: "";
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 5px;
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background-color: #fff;
					transition: background-color 0.3s ease;
				}
			}
		}
		input:checked + label::before {
			background-color: black;
		}
	}
}

.upload-box {
	border: 2px dashed #707070;
	border-radius: 10px;
	height: auto;
	// padding: 30px;
	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}
	.browse {
		color: var(--main-color);
		&:hover {
			cursor: pointer;
		}
	}
}

.drag-drop {
	display: block;
	position: relative;
	text-align: center;
	margin: 30px 0;
	.outer-box {
		border: dashed #707070 3px;
		border-radius: 10px;
		background-color: rgba(255, 255, 255, 0.8);
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		z-index: 9999;
	}
	.inner-box {
		position: absolute;
		top: 50%;
		right: 0;
		left: 0;
		text-align: center;
		color: grey;
		font-size: 36px;
	}
}

.element-date {
	position: relative;
	max-width: 170px;
	.react-datepicker-popper {
		z-index: 5;
	}
	.react-datepicker-wrapper input {
		padding: 10px;
		font-size: 13px;
		font-weight: 300;
		border: 2px solid theme-color("light");
		border-radius: 5px;
		position: relative;
		width: 100%;

		&::placeholder {
			font-size: 13px;
			font-weight: 500;
			color: theme-color("light");
		}
	}
}
.select-container {
	display: inline-block;
	img {
		content: "";
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 15px;
		width: 20px;
		height: 20px;
		pointer-events: none;
	}
}

// Loading crap
.loading-text {
	background: grey;
}

.close-edit-menu {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 19;
}

.hidden {
	display: none;
}
.ReactCollapse--collapse {
	transition: height 500ms;
	transform: translate3d(0px, 0px, 0px);
}

.form-control.is-valid,
.was-validated .form-control:valid {
	background-position-x: calc(100% - 10px);
	background-position-y: center;
}

.center {
	text-align: center;
}

.radio-buttons input[type="radio"] {
	width: 0;
	position: absolute;
	& + label {
		margin: 5px;
	}
}

.custom-border {
	border: 3px dashed theme-color("light");
	border-image-source: url("../images/icons/dashedborder.png");
	border-image-slice: 1;
	border-image-repeat: round;
	line-height: 1.2;
}
.overview-show-more {
	text-align: center;
	text-transform: uppercase;
	font-size: 13px;
	color: theme-color("light");
	font-weight: 400;
	cursor: pointer;
}
.overview-categories-container {
	.overview-category-list-container {
		max-height: 390px;
		overflow-y: auto;
		&::-webkit-scrollbar {
			width: 10px;
		}
		/* Track */
		&::-webkit-scrollbar-track {
			box-shadow: none;
			background: #a0a1a5;
			border-radius: 10px;
		}
		/* Handle */
		&::-webkit-scrollbar-thumb {
			background: #6d6e71;
			border-radius: 10px;
		}
	}

	.overview-category {
		display: flex;
		cursor: pointer;
		margin-bottom: 10px;
		padding-right: 10px;
		.overview-category-content {
			flex: 1 1 auto;
		}
		&.no-category-item {
			align-items: center;
		}
		> svg {
			width: 20px;
			flex: 0 0 20px;
			margin-right: 7px;
			&,
			& * {
				fill: #6d6e71;
			}
		}
		.overview-category-actions {
			display: flex;
			align-items: flex-start;
			margin-top: -2px;
			opacity: 0;
			pointer-events: none;
			transition: all 0.2s;
			svg {
				width: 12px;
				height: auto;
				margin: 0 0 0 5px;
				flex: 0 0 12px;
			}
			.overview-delete-category,
			.overview-edit-category {
				cursor: pointer;
				&:hover {
					svg {
						&,
						& * {
							fill: var(--main-color);
						}
					}
				}
			}
		}

		.overview-category-label {
			font-size: 17px;
			text-transform: uppercase;
			margin: 0;
			color: #6d6e71;
		}
		.overview-category-location {
			font-size: 9px;
			font-weight: 300;
			color: #6d6e71;
		}
		&:hover,
		&.active {
			> svg {
				&,
				& * {
					fill: var(--main-color);
				}
			}
			.overview-category-label,
			.overview-category-location {
				color: var(--main-color);
			}
		}
		&:hover {
			.overview-category-actions {
				opacity: 1;
				pointer-events: all;
			}
		}
	}
	.outer-overview-category {
		max-width: 250px;
		&:last-child .overview-category {
			margin-bottom: 0;
		}
	}
	.add-category-item {
		padding: 13px 0;
		border-top: 1px solid theme-color("light");
		border-bottom: 1px solid theme-color("light");
		.overview-category-label {
			color: #c1c3c4 !important;
		}
		svg {
			&,
			& * {
				fill: theme-color("light") !important;
			}
		}
	}
}
.category-modal-container {
	width: 100%;
	max-width: 348px;
	margin: 0 auto;
	.category-modal-existing-owner {
		padding: 7px 10px;
		width: 100%;
		margin-bottom: 20px;
	}
	label {
		display: block;
		font-weight: 700;
		color: #555454;
	}
	.category-modal-label-container input {
		width: 100%;
		max-width: 348px;
		padding: 10px;
		border-radius: 5px;
		border: 1px solid hsl(0, 0%, 80%);
	}
}
.tooltip--library {
	margin-top: 3px !important;
	background: #f5f4f4 !important;
	color: #000 !important;
	padding: 5px 15px !important;
	font-size: 12px !important;
	max-width: 210px !important;
}
.color-picker-container {
	position: absolute;
	top: 100%;
	right: 0;
	svg {
		display: none;
	}
	.flexbox-fix:last-child .flexbox-fix + div {
		display: none;
	}
	.chrome-picker {
		position: relative;
		z-index: 2;
	}
}
.date-last-updated {
	font-size: 10px;
	font-style: italic;
	color: #96999c;
	overflow: hidden;
}

.library-box-info {
	max-width: 100%;
	.library-box-info-item {
		max-width: 100%;
		strong {
			margin-right: 5px;
		}
	}
}
.list {
	.library-box-info {
		display: flex;
		margin-top: 0 !important;
		flex-wrap: wrap;
		.library-box-info-item {
			margin-right: 10px;
			margin-bottom: 0;
			strong {
				text-transform: uppercase;
				color: #555454;
			}
		}
	}
}
.file {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 10px 0;
	.file-type {
		width: 47px;
		height: 47px;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		border: 1px solid var(--main-color);
		display: flex;
		font-size: 14px;
		font-weight: 700;
		color: var(--main-color);
		margin-right: 5px;
		text-transform: uppercase;
	}
	.file-info {
		position: relative;
		display: inline-block;
		padding-right: 25px;
		.file-name-container {
			display: flex;
			justify-content: space-between;
			.file-name {
				width: 60%;
				font-size: 15px;
				color: #555454;
				margin-bottom: 5px;
				font-weight: 700;
				background: #fff;
				border: 0;
				margin-right: 5px;
				padding: 5px;
			}
			span {
				cursor: pointer;
				font-size: 11px;
				margin-top: 8px;
				color: map-get($colors, "blue");
				font-weight: 600;
				text-transform: capitalize;
				line-height: 1;
			}
		}
		.file-size {
			font-size: 10px;
			color: #555454;
			padding-left: 5px;
			display: flex;
			justify-content: flex-end;
		}
		.delete-file {
			height: 16px;
			width: 16px;
			text-align: center;
			color: #fff;
			background-color: rgba(0, 0, 0, 0.4);
			border-radius: 50%;
			font-size: 12px;
			position: absolute;
			right: 0;
			top: 20px;
			line-height: 0;
			cursor: pointer;
			-webkit-user-select: none;
			user-select: none;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-bottom: 1px;
			p {
				margin: 0;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				height: 1px;
				width: 6px;
			}
		}
	}
}
.swal-email-admin {
	max-width: 250px !important;
	.swal2-popup {
		padding: 15px !important;
		display: flex !important;
		flex-direction: column !important;
		.swal2-header,
		.swal2-content {
			margin-bottom: 10px !important;
		}
		.swal2-confirm {
			background: var(--main-color) !important;
		}
	}
}
.library-card-title .line-clamp .line-clamp-multi {
	max-width: none !important;
	max-height: 125px !important;
	overflow: hidden !important;
}

.async-select-option-title {
	font-size: 9px;
	text-transform: uppercase;
	font-weight: 400;
}

.remove-styles {
	&,
	& *:not(*[class*="fr"]):not(*[class*="fa"]) {
		all: initial;
	}
}

.riley-overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	z-index: 30;
	background: #fff;
	display: flex;
	flex-direction: column;
	overflow: auto;
	.content {
		flex: 1 1 auto;
	}
	.overlay-close-button-wrapper {
		padding: 15px;
	}
}

.builder-bread-crumb {
	color: #555454;
	margin-bottom: 25px;
	font-size: 15px;
	display: block;
	&:hover {
		color: #555454;
	}
}

.pointer {
	cursor: pointer;
}

.content-shadow {
	box-shadow: 0 -8px 20px -7px rgb(0 0 0 / 22%);
}

.pattern-background {
	background-image: url("../images/pattern-background.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.btn.btn-regular {
	border-radius: 0.4rem !important;
}

.capitalize {
	text-transform: capitalize !important;
}
