@import "assets/css/abstracts/fontSizes";
@import "assets/css/abstracts/colors";

.ss-button {
	position: relative;
	display: flex;
	justify-content: center;
	border: none;
	border-radius: 0.4rem;
	background-color: #4361ee;
	color: #fff;
	font-size: map-get($fontSizes, "medium");
	font-family: "Montserrat", sans-serif;
	font-weight: bold;
	line-height: 1.2;
	letter-spacing: 1px;
	padding: 0.75rem 1.5rem;

	.btn-icon {
		fill: #fff;
		color: #fff;
		height: map-get($fontSizes, "medium");
		width: map-get($fontSizes, "medium");

		&--left {
			margin-right: 0.25rem;
		}

		&--right {
			margin-left: 0.25rem;
		}
	}

	&:hover {
		opacity: 0.85;
	}

	&.primary {
		background-color: var(--main-color);
	}

	&.secondary {
		background-color: map-get($colors, "grey");
	}

	&.danger {
		background-color: map-get($colors, "red");
	}

	&.outline {
		background-color: #fff;
		border: 1px solid map-get($colors, "dark-grey");
		color: map-get($colors, "dark-grey");

		.btn-icon {
			fill: map-get($colors, "dark-grey");
			color: map-get($colors, "dark-grey");
		}
	}

	&.text {
		background-color: transparent;
		color: var(--main-color);
		padding: 0 !important;

		.btn-icon {
			fill: var(--main-color);
			color: var(--main-color);
		}
	}

	&.small {
		font-size: map-get($fontSizes, "small");
		font-weight: bold;
		padding: 0.8rem 1.6rem;

		.btn-icon {
			height: map-get($fontSizes, "small");
			width: map-get($fontSizes, "small");
		}
	}

	&.loading {
		span {
			visibility: hidden;
		}

		.loading-dots {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			div {
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 0.25rem;
				background-color: #fff;
				opacity: 0;
				transform: scale(1);
			}

			.loading-dot-1 {
				animation: dot-flashing 1.25s infinite linear;
			}

			.loading-dot-2 {
				margin: 0 0.5rem;
				animation: dot-flashing 1.25s infinite linear;
				animation-delay: 0.25s;
			}

			.loading-dot-3 {
				animation: dot-flashing 1.25s infinite linear;
				animation-delay: 0.5s;
			}
		}

		@keyframes dot-flashing {
			0% {
				opacity: 0.1;
				transform: scale(1);
			}
			50% {
				opacity: 1;
				transform: scale(1.5);
			}
			100% {
				opacity: 0.1;
				transform: scale(1);
			}
		}
	}
}
