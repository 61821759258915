:root {
	--main-color: var(--blue);
	--secondary-color: var(--pink);
	--dashboard-button-1-color: var(--blue);
	--dashboard-button-2-color: var(--blue);
	--dashboard-button-3-color: var(--blue);
	--dashboard-button-4-color: var(--blue);
	--page-header-color: linear-gradient(to right, #1ddaff 0%, #4361ee 100%);
}

.main-color {
	color: var(--main-color);
}
.secondary-color {
	color: var(--secondary-color);
}
.bg-main-color {
	background-color: var(--main-color);
}
.bg-secondary-color {
	background-color: var(--secondary-color);
}

// GRADIENTS
.gradient-light-blue {
	background: #17adcb !important;
	background: linear-gradient(
		90deg,
		#3cdfff 0%,
		#1ddaff 50%,
		#17adcb 100%
	) !important;
}

.gradient-blue {
	background: #2c45b9 !important;
	background: linear-gradient(
		90deg,
		#7188f6 0%,
		#4361ee 50%,
		#2c45b9 100%
	) !important;
}

.gradient-purple {
	background: #5c0a91 !important;
	background: linear-gradient(
		90deg,
		#a34cdd 0%,
		#7209b7 50%,
		#5c0a91 100%
	) !important;
}

.gradient-fuchsia {
	background: #98157a !important;
	background: linear-gradient(
		90deg,
		#f562d3 0%,
		#db2ab2 50%,
		#98157a 100%
	) !important;
}

.gradient-pink {
	background: #a8185a !important;
	background: linear-gradient(
		90deg,
		#f779b3 0%,
		#f72585 50%,
		#a8185a 100%
	) !important;
}

.gradient-salmon {
	background: #c45f54 !important;
	background: linear-gradient(
		90deg,
		#f9aaa2 0%,
		#ef766a 50%,
		#c45f54 100%
	) !important;
}

.gradient-red {
	background: #c3043f !important;
	background: linear-gradient(
		90deg,
		#fc6493 0%,
		#ff004e 50%,
		#c3043f 100%
	) !important;
}

.gradient-dark-red {
	background: #90022d !important;
	background: linear-gradient(
		90deg,
		#e93269 0%,
		#c3033d 50%,
		#90022d 100%
	) !important;
}

.gradient-green {
	background: #79c383 !important;
	background: linear-gradient(
		90deg,
		#a3eeaa 0%,
		#93e29e 50%,
		#79c383 100%
	) !important;
}

.gradient-yellow {
	background: #ddc05f !important;
	background: linear-gradient(
		90deg,
		#ffe89b 0%,
		#ffe17c 50%,
		#ddc05f 100%
	) !important;
}
