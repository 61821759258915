//Set of utilities, necessary to use Bootstrap media query mixins
@import "vendor/bootstrap/scss/functions";
@import "vendor/bootstrap/scss/variables";
@import "vendor/bootstrap/scss/mixins";
@import "assets/css/abstracts/colors";

//TODO: overwrite the rest of bootstraps color variables, $colors and $grays
$primary: map-get($colors, "light-blue");
$secondary: map-get($colors, "pink");
$danger: map-get($colors, "red");
$success: map-get($colors, "green");
$warning: map-get($colors, "yellow");
$info: map-get($colors, "light-blue");
$light: map-get($colors, "grey");
$dark: map-get($colors, "dark-grey");
$container-max-widths: (
	xl: 1400px,
);

$theme-colors: (
	"primary": $primary,
	"secondary": $secondary,
	"danger": $danger,
	"success": $success,
	"warning": $warning,
	"info": $info,
	"light": $light,
	"dark": $dark,
);

$enable-responsive-font-sizes: true;
