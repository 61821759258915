@import "assets/css/vendor-overrides/bootstrap";

$rt-color-success: map-get($theme-colors, "success");
$rt-color-warning: map-get($theme-colors, "warning");
$rt-color-error: map-get($theme-colors, "danger");

@import "~react-toastify/scss/main.scss";

.#{$rt-namespace}__toast-container {
	&--bottom-left {
		bottom: 3rem;
		left: 3rem;
		width: auto;
	}
}

.#{$rt-namespace}__toast {
	border-radius: 5px;
}
