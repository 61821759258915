@import "assets/css/vendor-overrides/bootstrap-utilities";

$swal2-success: map-get($theme-colors, "success");
$swal2-success-border: rgba($swal2-success, 0.3);
$swal2-error: map-get($theme-colors, "danger");
$swal2-warning: map-get($theme-colors, "warning");
$swal2-info: map-get($theme-colors, "info");
$swal2-question: #87adbd;
$swal2-confirm-button-background-color: var(--main-color);
$swal2-confirm-button-border: 2px solid var(--main-color);
$swal2-cancel-button-background-color: map-get($theme-colors, "light");
$swal2-cancel-button-border: 2px solid map-get($theme-colors, "light");
$swal2-button-focus-outline: rgba(0, 123, 255, 0.25);
$swal2-button-darken-hover: #ffffff;
$swal2-button-darken-active: #ffffff;

@import "~sweetalert2/src/sweetalert2";

.swal2-popup {
	width: 40em !important;
	padding: 3.05em !important;
}

.swal2-styled {
	text-transform: uppercase;
	font-family: "Montserrat", sans-serif;
	font-weight: 700;
	font-size: 0.8125rem;
	transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
		border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	flex: 1 1 0;
	min-width: 125px;

	&:focus {
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	}

	&:hover {
		background-color: #ffffff;
	}

	&.swal2-confirm {
		font-size: 0.96em;
		&:hover {
			color: var(--main-color);
		}
	}

	&.swal2-cancel {
		&:hover {
			color: map-get($theme-colors, "light");
		}
	}

	&.swal2-cancel.danger {
		background-color: map-get($theme-colors, "danger");
		border-color: map-get($theme-colors, "danger");
		&:hover {
			color: map-get($theme-colors, "danger");
		}
	}

	&.swal2-confirm.danger {
		background-color: map-get($theme-colors, "light");
		border-color: map-get($theme-colors, "light");
		&:hover {
			color: map-get($theme-colors, "light");
		}
	}
}
